// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  currency: "GHS",
  // apiUrl: 'https://app.alias-solutions.net:5020/',
  // apiUrl: 'http://localhost:54630/',
  // imageUrl: 'http://localhost:54634/Files/', 
  // api: "http://localhost:21021/api/services/app/",
  // apiUrl: 'http://3.212.216.125:86/',
  // imageUrl: 'http://3.212.216.125:86/Files/',
  // Live Vm2
  // apiUrl: 'https://app.alias-solutions.net:5024/',
  // imageUrl: 'https://app.alias-solutions.net:5024/Files/',
  // Dev Vm2
  // apiUrl: "https://api.alias-solutions.net:8449/",
  // imageUrl: 'https://app.alias-solutions.net:8449/Files/',
  // api: 'https://app.alias-solutions.net:5021/api/services/app/',
  // Test vm2
  // apiUrl: 'https://app.alias-solutions.net:5020/',
  // imageUrl: 'https://app.alias-solutions.net:5020/Files/',
  // api: 'https://app.alias-solutions.net:5007/api/services/app/',
  // Aws
  // apiUrl: 'https://api.cross-switch.app:86/',
  // imageUrl: 'https://api.cross-switch.app:86/Files',
  // api: 'https://api.cross-switch.app:86/api/services/app/',
  // apiUrl: 'https://app.alias-solutions.net:5002/',
  // imageUrl: 'https://app.alias-solutions.net:5002/Files/',
  // api: 'https://app.alias-solutions.net:5021/api/services/app/',


  // CS Ghana AWS
  apiUrl: 'https://api-gh.cspay.app/',
  imageUrl: 'https://api-gh.cspay.app/Files/',
  api: 'https://api-ic-v1.cspay.app/api/services/app/',
  // api: 'https://api.alias-solutions.net:8001/api/services/app/',

  // CS Benin AWS
  // apiUrl: 'https://api-bj.cspay.app/',
  // imageUrl: 'https://api-bj.cspay.app/Files/',
  // api: 'https://api-ic-v1.cspay.app/api/services/app/',
  firebase: {
    apiKey: 'AIzaSyBUDgMtElCaHYxgdZpehxiWg5LQFiFIySE',
    authDomain: 'paynowafrica.firebaseapp.com',
    databaseURL: 'https://paynowafrica.firebaseio.com',
    projectId: 'paynowafrica',
    storageBucket: 'paynowafrica.appspot.com',
    messagingSenderId: '287500243914',
    appId: '1:287500243914:web:b49403c871c54856'
  }
};

// export const config = {
//   // apiUrl: 'http://192.168.1.102:8443/chatbotapi/',
//   // imageUrl: 'http://192.168.1.102:8443/chatbotapi/Files/',
//   // apiUrl: 'https://api-fsp.paynowafrica.com/',
//   apiUrl: 'https://app.alias-solutions.net:5020/',
//   api: 'https://app.alias-solutions.net:5021/api/services/app/',
//   // apiUrl: 'https://app.alias-solutions.net:5002/',
//   // api: 'https://app.alias-solutions.net:5007/api/services/app/',
//   imageUrl: 'https://api-fsp.paynowafrica.com/Files/',
//   firebase: {
//     apiKey: 'AIzaSyBUDgMtElCaHYxgdZpehxiWg5LQFiFIySE',
//     authDomain: 'paynowafrica.firebaseapp.com',
//     databaseURL: 'https://paynowafrica.firebaseio.com',
//     projectId: 'paynowafrica',
//     storageBucket: 'paynowafrica.appspot.com',
//     messagingSenderId: '287500243914',
//     appId: '1:287500243914:web:b49403c871c54856'
//   }
// };


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
